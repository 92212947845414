async function areYouARobot() {
  const url = process.env.GATSBY_RECAPTCHA_CHECK_URL
  const token = await fetcgReCaptchaToken()
  const validityCheck = await fetch(url, {
    method: "post",
    body: JSON.stringify({ token: token }),
  })
    .then(res => {
      //   console.log(
      //     "captcha check result",
      //     res,
      //     process.env.GATSBY_RECAPTCHA_CHECK_URL
      //   )
      return res.json()
    })
    .then(json => json)

  return validityCheck
}

function fetcgReCaptchaToken() {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(function () {
      return window.grecaptcha
        .execute("6LcQXN8ZAAAAAM_T4EQYyYZ0jutEIjEhGnQbdduW", {
          action: "submit",
        })
        .then(token => {
          resolve(token)
        })
    })
  })
}

export { areYouARobot }
