import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"

import LargeHeader from "../components/LargeHeader"
import { EMail, SpinnerPuff } from "../assets/icons"
import { areYouARobot } from "../utility"
import SEO from "../components/seo"

export default function Home({ data }) {
  const [contactInfo, setContactInfo] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const { image1 } = data

  async function unlockContactInfo(e) {
    if (!contactInfo && !loading) {
      setLoading(true)
      const ci = await areYouARobot()
      // too quick :)
      setTimeout(() => {
        setContactInfo(ci)
        setLoading(false)
      }, 1000)
    }
  }

  return (
    <>
      <SEO
        title="Contact"
        description="Let's get in touch. I'd love to hear from you."
        article={false}
      />
      <LargeHeader image={image1} style={{ marginBottom: "80px" }}>
        <h1 className="giant">Get in touch!</h1>
        <h3 className="primary">I'd love to hear from you...</h3>

        <p>
          ...but I don't want to get spammed.
          <br />
          Please click below to unlock my email address... if you are human. 😎
        </p>

        {contactInfo ? (
          <ContactInfo contactInfo={contactInfo} />
        ) : (
          <UnlockContactInfo
            unlockContactInfo={unlockContactInfo}
            loading={loading}
          />
        )}
      </LargeHeader>
    </>
  )
}

const UnlockButton = styled.a`
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
    margin-left: 10px;
  }
`

export function UnlockContactInfo({ loading, unlockContactInfo }) {
  return (
    <>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LcQXN8ZAAAAAM_T4EQYyYZ0jutEIjEhGnQbdduW"></script>
      </Helmet>
      <UnlockButton className="button" onClick={unlockContactInfo}>
        Unlock Contact info {loading ? <SpinnerPuff /> : ""}
      </UnlockButton>
    </>
  )
}

const ContactList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  svg {
    height: 20px;
    width: 20px;

    path {
      fill: #fff;
      fill-opacity: 1;
    }
  }

  a,
  a:visited {
    //color: var(--primary-color);
    text-decoration: none;
  }
`

export function ContactInfo({ contactInfo }) {
  const { email } = contactInfo

  if (email) {
    return (
      <ContactList>
        <li>
          <EMail />{" "}
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            {email}
          </a>
        </li>
      </ContactList>
    )
  }

  return <p>It seems like you are a robot.</p>
}

//  <Phone /> <a href="tel:+491727313982">+49 172 731 39 82</a><br />

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "thommy-1200.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1250) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
